import { Component, Output, Input, EventEmitter, AfterViewInit, ViewChild, ElementRef, HostListener, SimpleChanges } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth-service';
import { environment } from '../../environments/environment'
import { NbSidebarService } from '@nebular/theme';
import { throws } from 'assert';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { flatten } from 'flat';
import {TranslateService} from '@ngx-translate/core';
import { Clipboard } from '@angular/cdk/clipboard';
import Pusher from 'pusher-js';

import * as Papa from 'papaparse';
import { LayoutService } from '../@core/utils';
import { EmbeddedCodeComponent } from './components/embedded-code/embedded-code.component';
import { UserUpdateComponent } from './components/user-update/user-update.component';
import { ToasterService } from '../@theme/components/toaster/toaster.service';
import { BusinessInformationComponent } from './components/business-information/business-information.component';
import { TypeWriterTextService } from '../@theme/components/type-writer-text/type-writer-text.service';
import { cloneDeep } from 'lodash';
import { saveAs } from '../../../node_modules/file-saver';
import { HelperService } from '../@theme/services/core-services/helper.service';
declare var gapi: any;
@Component({
  selector: 'ngx-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss']
  // template: `
  //   <form (ngSubmit)="login()">
  //     <input type="text" [(ngModel)]="username" name="username" placeholder="Username">
  //     <input type="password" [(ngModel)]="password" name="password" placeholder="Password">
  //     <button type="submit">Login</button>
  //   </form>
  // `
})
export class LeftSidebarComponent implements AfterViewInit {
  @Output() messageEvent = new EventEmitter<string>();
  @Output() brokerEvent = new EventEmitter<string>();
  @Output() applicationEvent = new EventEmitter<number>();
  @Output() applicantEvent = new EventEmitter<number>();
  @Output() getActiveApplicant = new EventEmitter<object>();
  @Output() kingstateNewsEvent = new EventEmitter<string>();
  @Output() bbModalOpen = new EventEmitter<boolean>();
  @Output() setGoToApplication = new EventEmitter<boolean>();
  @Output() lockingEvent = new EventEmitter<boolean>();
  @Output() loggedInUpdater = new EventEmitter<boolean>();
  @Output() loaderUpdater = new EventEmitter<boolean>();
  @Output() csvModalValue = new EventEmitter<{ arg1: string, arg2: string }>();
  @Input() appExist: number; //
  @ViewChild(EmbeddedCodeComponent, { static: false }) embeddedCodeComponent: EmbeddedCodeComponent;
  @ViewChild(UserUpdateComponent, { static: false }) userUpdateComponent: UserUpdateComponent;
  @ViewChild(BusinessInformationComponent, { static: false }) businessInformationComponent: BusinessInformationComponent;
  pusher;
  isLoggedIn = false;
  registerActive = false;
  loginActive = true;
  mainOptions = true;
  showProfileApplicant = false;
  showApplicants = false;
  showPerferences = false;
  showProfile = false;
  showIdentityProof = false;
  showSubscriptions = false;
  showBilling = false;
  portalUrl = null;
  newApplicationBroker = false;
  seeApplications = false;
  applications = null;
  application = null;
  applicants = null;
  broker_hash = null;
  isIframe = false;
  applicantDetails = false;
  selectedApplicant = null;
  showApplicantsApplications = false;
  newApplicantForm = false;
  applicantsApplications = null;
  application_name = null;
  new_name = '';
  new_lastName = '';
  new_email = '';
  new_phone = '';
  new_password = '';
  newApplication = false;
  socialUser = null;
  brokerAccountRequest = false;
  brokerAccountPending = false;
  resettingUser = null;
  brokerAccountReject = false;
  paymentMode = false;
  pusherConnection = false;
  selectedApplication = null;
  broker_hash_url = 'Loading ...';
  initialString: string = ''
  tooltipText: string = 'Copy to clipboard';
  embeddedSources = [];
  broker = null;
  screenWidth: number;
  mobileScreen = false;
  appDetailShow = false;
  setNewPassword = false;
  setResetPassword = false;
  resetPassOTP_Section = false;
  resetPassEmailSection = false;
  getResetPasswordEmail = null;
  getResetPasswordOTP = null;
  applicantPhone = '';
  setFormattedPhoneNumber = '';
  emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  editApplicantInfo = false;
  selectedOptionApplicantApplications: string = 'updated_at';
  selectedOptionApplications: string = 'updated_at';
  selectedOptionApplicants: string = 'updated_at';
  getBrokerProfile: string = 'assets/images/kingstate_logo.png';
  checkBrokerProfile = false;
  businessInfoSection = false;
  initialApplicationLoad = false;
  isUpdateButtonEnabled = false;
  updatingLoader = false;
  newApplicantWithBtn = false;
  tabScreen = false; 
  newApplicantId = 0;
  getApplicantId = 0;
  applicantData = {
    name: '',
    last_name: '',
    email: '',
    phone: ''
  };
  userUpdateChecker = {
    name: '',
    last_name: '',
    email: '',
    phone: ''
  }
  updatePassword = {
    newPassword: '',
    newPasswordConfirm: '',
  } 
  getResetPassword = {
    newPassword: '',
    newPasswordConfirm: '',
  } 
  ngAfterViewInit() {
    gapi.signin2.render('google-signin', {
      'scope': 'profile email',
      'width': 200,
      'height': 50,
      'longtitle': true,
      'theme': 'dark',
      'onsuccess': this.onSignIn,
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.appExist) {
      this.onAppExistChange(this.appExist);
    }
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.checkLoggedIn();
    if(!this.isLoggedIn){
      this.http.get<any>(environment.apiBaseUrl+'broker-check/'+this.broker_hash).subscribe(
        response => {
          this.isIframe = response.success;
          if(response.broker){
              this.broker = response.broker;
              this.sendBroker();
          }
        },
        error => {
          console.error('Login error:', error);
          console.error('Login error:', environment.apiBaseUrl);
        }
      );
    }
    if(this.user && this.isLoggedIn){
      this.authService.getInterceptedResponse().subscribe((response) => {
        if (response.statusText === "Unauthorized" && response.status === 401) {
          this.http.get<any>(environment.apiBaseUrl+'logout').subscribe(
            response => {
              this.user = null;
              this.sendUser();
              console.log(response);
              this.isLoggedIn = false;
              this.checkLoggedIn();
            },
            error => {
              console.error('Login error:', error);
              this.showError('Error', 'Error');
            }
          );
        }
      });
    }
  }

  email: string = '';
  password: string = '';
  public name = '';
  user = null;
  constructor(private http: HttpClient, 
              private authService: AuthService, 
              private toastrService: ToasterService, 
              private route: ActivatedRoute, 
              private router: Router, 
              private translate: TranslateService, 
              private clipboard: Clipboard, 
              private sidebarService: NbSidebarService, 
              private layoutService: LayoutService,
              private typeWriterTextServices: TypeWriterTextService,
              public elementRef: ElementRef,
              private helperService: HelperService) {
    
    if (this.authService.isTokenExpired()) {
      this.authService.removeToken();
    }
    const id = this.route.snapshot.queryParamMap.get('broker_hash');
    console.log('broker_hash');
    console.log(id);
    const app_id = this.route.snapshot.queryParamMap.get('app_id');
    const authentication_token = this.route.snapshot.queryParamMap.get('authentication_token');
    const language = this.route.snapshot.queryParamMap.get('lang');
    const applicant_hash = this.route.snapshot.queryParamMap.get('applicant_hash');
    translate.setDefaultLang('fr');
    if(language){
      translate.use(language);
    }
    
    if(id){
      this.broker_hash = id;
      sessionStorage.setItem('broker_hash', this.broker_hash);
    }
    if(app_id && authentication_token){
      const retrievedBrokerHash = sessionStorage.getItem('broker_hash');
      sessionStorage.removeItem('broker_hash');
      this.http.post<any>(environment.apiBaseUrl+'redirect/authentication', { app_id: app_id, authentication_token: authentication_token, broker_hash: retrievedBrokerHash }).subscribe(
        response => {
          if(response.token){
            const token = response.access_token;
            this.authService.saveToken(token);
            this.checkLoggedIn();
            this.showSuccess('Login Successful','')
          } else {
            if(response.error){
              this.showError('Error', response.error_message);
            } else {
              this.showSuccess('User Updated','');
              this.checkLoggedIn();
            }
          }
          
          this.removeQueryParams();
        },
        error => {
          console.error('Login error:', error);
          this.showError('Unauthorized', 'Invalid email or password');
        }
      );
    }
    if(applicant_hash){
      this.http.post<any>(environment.apiBaseUrl+'applicant/hash-login', { applicant_hash: applicant_hash}).subscribe(
        response => {
          this.lockingEvent.emit();
          const token = response.access_token;
          const open_application = response.application_id;
          this.authService.saveToken(token);
          this.checkLoggedIn();
          this.showSuccess('Login Successful','')
          // this.showSuccess('Application ID ' + open_application, '');
          this.removeQueryParams();
          this.openEditApplications()
          this.sendApplication(open_application);
        },
        error => {
          console.error('Login error:', error);
          this.showError('Unauthorized', 'Invalid url');
        }
      );
    }
  }

  languageSwitcher(lang) {
    this.translate.setDefaultLang('fr');
    this.translate.use(lang);
  }
  
  removeQueryParams() {
    const navigationExtras: NavigationExtras = {
      queryParams: {}
    };
    this.router.navigate([], navigationExtras);
  }

  public onSignIn(googleUser) {
    var profile = googleUser.getBasicProfile();
    console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
    console.log('Name: ' + profile.getName());
    console.log('Image URL: ' + profile.getImageUrl());
    console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
  }

  updateSendUser(data: any){
    if(data){
      this.checkLoggedIn();
    }
  }

  sendUser() {
    const message = this.user;
    this.messageEvent.emit(message);
  }

  sendBroker() {
    const message = this.broker;
    this.brokerEvent.emit(message);
  }

  sendApplication(index: number, name: string = null) {
    this.appDetailShow = true;
    if(name){
      this.application_name = name;
    }
    this.applicationEvent.emit(index);
    if(this.user.role.name === 'broker'){
      if(this.selectedApplicant){
        this.applicantEvent.emit(this.selectedApplicant.id);
      }
    } else if(this.user.role.name === 'applicant'){
      this.applicantEvent.emit(this.user.id);
    }
    this.initialApplicationLoad = true;
    this.typeWriterTextServices.setApplicationVariable(this.initialApplicationLoad);
  }

  sendApplicationShareable(index: number, name: string = null) {
    if(name){
      this.application_name = name;
    }
    //Rest of the stuff goes here
    this.selectedApplication = index;
    this.http.get<any>(environment.apiBaseUrl+'applicant/get-hash-url/'+index).subscribe(
      response => {
        this.broker_hash_url = response.url;
        this.checkApplicantDetails();
        this.applicationEvent.emit(index);
        if(this.selectedApplicant){
          this.applicantEvent.emit(this.selectedApplicant.id);
          this.selectedApplicant.phone = this.helperService.getFormattedPhoneNumber(this.selectedApplicant.phone);
        }
        this.initialApplicationLoad = true;
        this.typeWriterTextServices.setApplicationVariable(this.initialApplicationLoad);
      },
      error => {
        console.error('Login error:', error);
      }
    );
  }

  checkApplicantDetails(){
    if(this.applicantDetails){
      this.showApplicantsApplications = true;
      this.appDetailShow = true;
    }
  }

  onAppExistChange(index) {
    if(index){
      this.sendApplicationShareable(index);
    }
  }

  getApplicant(id: number) {
    this.http.get<any>(environment.apiBaseUrl+'get-applicant/'+id).subscribe(
      response => {
        this.selectedApplicant = response;
        this.getActiveApplicant.emit(this.selectedApplicant);
        this.applicantData = {
          name: this.selectedApplicant.name, 
          last_name: this.selectedApplicant.last_name, 
          email: this.selectedApplicant.email, 
          phone: this.selectedApplicant.phone
        }
        this.closeAllOther();
        this.applicantDetails = true;
        this.showApplicants = false;
        this.selectedApplicant.phone = this.helperService.getFormattedPhoneNumber(this.selectedApplicant.phone);
        this.getApplicantsApplications(response.id);
        if(this.newApplicantWithBtn){
          this.newApplicantWithBtn = false;
          setTimeout(()=>{
            this.addApplication();
          }, 300);
        }
      },
      error => {
        console.error('Login error:', error);
      }
    );
  }
  setUserLogin(userEmail: string, userPass: string){
    console.log("setUserLogin")
    this.email = userEmail;
    this.password =userPass;
    this.login();
  }
  public login(): void {
    this.http.post<any>(environment.apiBaseUrl+'login', { email: this.email, password: this.password }).subscribe(
      response => {
        const token = response.access_token;
        this.authService.saveToken(token);
        this.checkLoggedIn();
        this.showSuccess('Login Successful','')
        setTimeout(() => {
          this.bottomBtnsPosition();
        }, 200);
        // Redirect or perform any necessary action after successful login
      },
      error => {
        console.error('Login error:', error);
        this.showError('Unauthorized', 'Invalid email or password');
      }
    );
  }

  checkLoggedIn() {
    this.http.get<any>(environment.apiBaseUrl+'me').subscribe(
      response => {
        if(response.error){
          // this.showError('Error', response.message);
        } else {
          this.user = response;
          this.sendUser();
          console.log("checkLoggedIn",response);
          this.user.phone = this.helperService.getFormattedPhoneNumber(this.user.phone);
          if(this.user.role.name === 'applicant' && this.user.broker){
            this.user.broker.phone = this.helperService.getFormattedPhoneNumber(this.user.broker.phone);
          }
          if(this.user.business_information && this.user.business_information.phone){
            this.user.business_information.phone = this.helperService.getFormattedPhoneNumber(this.user.business_information.phone);
          }
          this.isLoggedIn = true;
          this.loggedInUpdater.emit(this.isLoggedIn);
          setTimeout(() => {
            this.bottomBtnsPosition();
            this.getBrokerProfile = '';
            this.brokerProfile(this.user);
          }, 200);
          if(!this.pusherConnection){
            this.pusher = new Pusher( environment.pusher_key, {
              cluster: 'us2',
            });
            var channel = this.pusher.subscribe('user-update-'+this.user.id);
            channel.bind('user', (data) => { 
              if(data.data.success || data.data.message === "Your role has been updated to applicant" || data.data.message === "Your role has been updated to broker"){
                this.showSuccess(data.data.message,'');
                
              } else {
                this.showError(data.data.message,'');
              }
              this.checkLoggedIn();
            });
            this.pusherConnection = true;
          }
        }
      },
      error => {
        this.isLoggedIn = false;
        this.loggedInUpdater.emit(this.isLoggedIn);
        this.getBrokerProfile = 'assets/images/kingstate_logo.png';
        this.checkBrokerProfile = false;
        console.error('Login error2 - TESTER:', error);
        if(error.error.message == 'Subscription Ended' && error.status == 402){
          console.error('Subscription expired:', error);
          this.showError('Error', error.error.message);
          this.http.get<any>(environment.apiBaseUrl+'logout').subscribe(
            response => {
              this.user = null;
              this.sendUser();
              console.log(response);
              this.isLoggedIn = false;
            },
            error => {
              console.error('Login error:', error);
              this.showError('Error', 'Error');
            }
          );
        }
      }
    );
  }

  logout() {
    if(this.user){
      this.http.get<any>(environment.apiBaseUrl+'trigger-viewer-switch/'+this.selectedApplication+'/switch/'+0).subscribe();
      this.http.get<any>(environment.apiBaseUrl+'logout').subscribe(
        response => {
          this.user = null;
          this.sendUser();
          console.log(response);
          this.isLoggedIn = false;
          this.checkLoggedIn();
          this.showSuccess('Logout Successful','')
        },
        error => {
          console.error('Login error:', error);
          this.showError('Error', 'Error');
        }
      );
    }
  }

  setUserRegister(userName: string, userEmail: string, userPass: string){
    this.name = userName
    this.email = userEmail;
    this.password =userPass;
    this.register();
  }

  register() {
    this.http.post<any>(environment.apiBaseUrl+'register', { email: this.email, password: this.password, name: this.name, isApplicant: this.isIframe, brokers_hash: this.broker_hash }).subscribe(
      response => {
        const token = response.access_token;
        console.log('token '+token);
        this.authService.saveToken(token);
        this.checkLoggedIn();
        // Redirect or perform any necessary action after successful login
      },
      error => {
        if (error && error.error && error.error.errors) {
          const errorKeys = Object.keys(error.error.errors);
          errorKeys.forEach(key => {
            const errorArray = error.error.errors[key];
            if (Array.isArray(errorArray)) {
              errorArray.forEach(element => {
                this.showError('Error', element);
              });
            } else {
              console.error(`Invalid error array for attribute: ${key}`);
            }
          });
        } else {
          console.error('Invalid error object or missing properties');
        }
      }
    );
  }

  editApplicant(value: string){
    if(value === 'open'){
      this.editApplicantInfo = true;
    } else if(value === 'close'){
      this.editApplicantInfo = false;
      if(this.isUpdateButtonEnabled){
        const getApplicantPhone = cloneDeep(this.applicantData);
        this.selectedApplicant.name = getApplicantPhone.name;
        this.selectedApplicant.last_name = getApplicantPhone.last_name;
        this.selectedApplicant.email = getApplicantPhone.email;
        this.selectedApplicant.phone = getApplicantPhone.phone;
        this.selectedApplicant.phone = this.helperService.getFormattedPhoneNumber(this.selectedApplicant.phone);
        this.onInputChange()
      }
    }
  }
  
  applicantOriginalData(){
      const getApplicantPhone = cloneDeep(this.applicantData);
      this.selectedApplicant.name = getApplicantPhone.name;
      this.selectedApplicant.last_name = getApplicantPhone.last_name;
      this.selectedApplicant.email = getApplicantPhone.email;
      this.selectedApplicant.phone = getApplicantPhone.phone;
      this.selectedApplicant.phone = this.helperService.getFormattedPhoneNumber(this.selectedApplicant.phone);
      this.onInputChange()
  }

  updateSelectedApplicantEmail(getEmail:any){
    const getApplicantPhone = cloneDeep(this.applicantData);
    this.selectedApplicant.name = getApplicantPhone.name;
    this.selectedApplicant.last_name = getApplicantPhone.last_name;
    this.selectedApplicant.email = getEmail;
    this.selectedApplicant.phone = getApplicantPhone.phone;
    this.selectedApplicant.phone = this.helperService.getFormattedPhoneNumber(this.selectedApplicant.phone);
    this.updateApplicant();
  }

  updateApplicant(){
    this.updatingLoader = true;
    let unformatPhoneNumber = this.selectedApplicant.phone.replace(/-/g, '');
    if(unformatPhoneNumber && unformatPhoneNumber.length < 10){
      this.updatingLoader = false;
      this.showError('Error', 'Incorrect Number');
    } else {
      this.http.post<any>(environment.apiBaseUrl+'update-applicant/'+this.selectedApplicant.id,
       { name: this.selectedApplicant.name, last_name: this.selectedApplicant.last_name, email: this.selectedApplicant.email, phone: unformatPhoneNumber }).subscribe(
        response => {
          if(response.success){
            this.updatingLoader = false;
            this.showSuccess('User Updated','');
            this.editApplicantInfo = false;
            this.applicantData = {
              name: response.applicant.name, 
              last_name: response.applicant.last_name, 
              email: response.applicant.email, 
              phone: response.applicant.phone
            }
            this.onInputChange();
          } else {
            this.updatingLoader = false;
            this.showError('Error', response.message);
          }
        },
        error => {
          if(error.error.message){
            this.updatingLoader = false;
            this.showError('Error', error.error.message);
          } else {
            this.updatingLoader = false;
            this.showError('Error', error.statusText);
          }
        }
      );
    }
  }

  setUserEdit(userName: string, lastName:string, userEmail: string, userMobile: string){
    this.user.name = userName;
    this.user.last_name = lastName;
    this.user.email = userEmail;
    this.user.phone = userMobile;
    this.updateUser();
  }

  updateUser(){
    this.userUpdateComponent.updateUser();
  }

  setUserNewPassword(newPassword: string, newPasswordConfirm: string){
    this.updatePassword.newPassword = newPassword;
    this.updatePassword.newPasswordConfirm = newPasswordConfirm;
    this.updateUserPassword();
  }

  newPassword(){
    if(this.updatePassword.newPassword !== '' && this.updatePassword.newPasswordConfirm !== '') {
      if(this.updatePassword.newPassword === this.updatePassword.newPasswordConfirm){
        this.updateUserPassword();
      } else{
        this.showError('Error', "Password Doesn't Match")
      }
    } else {
      this.showError('Error', 'Input Feild Required')
    }
  }

  updateUserPassword() {
    this.http.post<any>(environment.apiBaseUrl+'update-password', { password: this.updatePassword.newPassword }).subscribe(
      response => {
        this.showSuccess('User Password Updated','');
        this.updatePassword.newPassword = '';
        this.updatePassword.newPasswordConfirm = '';
        this.checkLoggedIn();
        this.backToPerferences();
      },
      error => {
        this.showError('Error', 'Something went wrong');
      }
    );
    // Logic Of update password
    
  }

  triggerSms(phoneNumber: string) {
    this.http.post<any>(environment.apiBaseUrl+'sms-hash-url/'+this.selectedApplication, { phone: phoneNumber }).subscribe(
      response => {
        this.showSuccess('SMS Sent','');
      },
      error => {
        this.showError('Error', 'Something went wrong');
      }
    );
  }

  resetPassword(){
    if(this.getResetPassword.newPassword !== '' && this.getResetPassword.newPasswordConfirm !== '') {
      if(this.getResetPassword.newPassword === this.getResetPassword.newPasswordConfirm){
        this.http.post<any>(environment.apiBaseUrl+'password-reset-user', { id: this.resettingUser, password: this.getResetPassword.newPassword }).subscribe(
          response => {
            this.showSuccess('Password Reset','');
            this.openLogin();
          },
          error => {
            this.showError('Error', 'Invalid OTP');
          }
        );
        
      } else{
        this.showError('Error', "Password Doesn't Match")
      }
    } else {
      this.showError('Error', 'Input Feild Required')
    }
  }

  sendOTP(){
    if(this.getResetPasswordEmail !== null && this.getResetPasswordEmail !== ''){
      this.http.post<any>(environment.apiBaseUrl+'password-reset', { email: this.getResetPasswordEmail }).subscribe(
        response => {
          this.showSuccess('Email/SMS Sent','');
          this.openResetPasswordOTP();
        },
        error => {
          this.showError('Error', 'Something went wrong');
        }
      );
      // if(this.emailPattern.test(this.getResetPasswordEmail)){
      //   console.log("OTP_Email", this.getResetPasswordEmail);
      // } else {
      //   this.showError('Error', 'Incorrect Eamil')
      // }
    } else {
      this.showError('Error', 'Input Feild Required')
    }
  }

  verifyOTP(){
    if(this.getResetPasswordOTP !== null && this.getResetPasswordOTP !== ''){
      this.http.post<any>(environment.apiBaseUrl+'password-reset-otp', { otp: this.getResetPasswordOTP, email: this.getResetPasswordEmail }).subscribe(
        response => {
          this.showSuccess('OTP Verified','');
          this.openResetPassword();
          this.resettingUser = response.data;
        },
        error => {
          this.showError('Error', 'Invalid OTP');
        }
      );
    } else {
      this.showError('Error', 'Input Feild Required')
    }
  }

  openUpdatePassword(value: boolean){
    this.setNewPassword = value;
  }

  openResetPassword(){
    this.closeAllAuths()
    this.setResetPassword = true;
  }

  openResetPasswordEamil(){
    this.closeAllAuths()
    this.resetPassEmailSection = true;
  }
  
  openResetPasswordOTP(){
    this.closeAllAuths()
    this.resetPassOTP_Section = true;
  }

  openRegister() {
    this.closeAllAuths()
    this.registerActive = true;
  }

  openLogin() {
    this.closeAllAuths()
    this.loginActive = true;
  }

  closeAllAuths(){
    this.loginActive = false;
    this.registerActive = false;
    this.setResetPassword = false;
    this.resetPassEmailSection = false;
    this.resetPassOTP_Section = false;
  }
  
  showSuccess(title: String, message: String) {
    this.toastrService.success(title, message);
  }

  showError(title: String, message: String) {
    this.toastrService.danger(title, message);
  }

  showApplicantsFunction(){
    this.http.get<any>(environment.apiBaseUrl+'applicants-list').subscribe(
      response => {
        // this.applicants = response;
        this.showApplicants = true;
        this.mainOptions = false;
        this.applicantsSorting(response);
      },
      error => {
        console.error('Login error:', error);
        this.showError('Error', error.statusText);
      }
    );
  }

  applicantsSorting(data: any){
    this.applicants = data.sort((a, b) => {
      const dateA = new Date(a[this.selectedOptionApplicants]).getTime();
      const dateB = new Date(b[this.selectedOptionApplicants]).getTime();
      if (this.selectedOptionApplicants === 'name') {
        const nameA = a.name || '';
        const nameB = b.name || '';
        return nameA.localeCompare(nameB);
      } else if(this.selectedOptionApplicants === 'created_at'){
        return dateA - dateB;
      } else if(this.selectedOptionApplicants === 'updated_at') {
        return dateB - dateA;
      }
    });
  }

  onOptionChangeApplicants(option: string): void {
    this.selectedOptionApplicants = option;
    this.applicantsSorting(this.applicants);
  }

  showMyApplications() {
    this.checkLoggedIn();
    this.http.get<any>(environment.apiBaseUrl+'application-list').subscribe(
      response => {
        console.log(response);
        this.applications = response;
        this.mainOptions = false;
        this.seeApplications = true;
      },
      error => {
        console.error('Login error:', error);
        this.showError('Error', error.statusText);
      }
    );
  }

  getApplicantsApplications(applicant_id: number) {
    this.http.get<any>(environment.apiBaseUrl+'applicants-applications/'+applicant_id).subscribe(
      response => {
        console.log(response);
        // this.showApplicantsApplications = true;
        // this.applicantDetails = false;
        // this.applicantsApplications = response;
        this.applicantApplicationSorting(response)
      },
      error => {
        console.error('Login error:', error);
        this.showError('Error', error.statusText);
      }
    );
  }

  applicantApplicationSorting(data: any){
    this.applicantsApplications= data.sort((a, b) => {
      const dateA = new Date(a[this.selectedOptionApplicantApplications]).getTime();
      const dateB = new Date(b[this.selectedOptionApplicantApplications]).getTime();
      if (this.selectedOptionApplicantApplications === 'application_name') {
        const nameA = a.application_name || '';
        const nameB = b.application_name || '';
        return nameA.localeCompare(nameB);
      } else if(this.selectedOptionApplicantApplications === 'created_at'){
        return dateA - dateB;
      } else if(this.selectedOptionApplicantApplications === 'updated_at') {
        return dateB - dateA;
      }
    });
  }
  
  onOptionChangeApplicantApplications(option: string): void {
    this.selectedOptionApplicantApplications = option;
    this.applicantApplicationSorting(this.applicantsApplications);
  }

  getNewApplicantData(data: any){
    if(data){
      this.new_email = data.email;
      this.new_name = data.name;
      this.new_phone =  data.phone;
      this.new_lastName = data.last_name;
      this.newApplicantWithBtn = true;
      this.createApplicant();
    }
  }

  createApplicant() {
    let unformatPhoneNumber = this.new_phone.replace(/-/g, '');
    const emailPattern =  /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(unformatPhoneNumber.length < 10 && unformatPhoneNumber.length > 0){
      this.showError('Error', 'Incorrect Number');
    } else if(this.new_email && this.new_email.length > 0 && !emailPattern.test(this.new_email)){
      this.showError('Error', 'Incorrect Email');
    } else {
      if(this.new_email !== '' || this.new_phone !== ''){
        this.http.post<any>(environment.apiBaseUrl+'create-applicant', { email: this.new_email, password: this.new_password, name: this.new_name, phone: unformatPhoneNumber, last_name: this.new_lastName}).subscribe(
          response => {
            if(response && response.id){
              this.newApplicantId = response.id
            }
            this.showSuccess('Applicant Successfully Created','')
            if(!this.newApplicantWithBtn){
              this.backTo1FromNewApplicant();
              this.backToMainFrom1();
              this.showApplicantsFunction();
            } else{
              this.showApplicantsFunction();
              this.getApplicant(this.newApplicantId);
            }
            this.new_name = null;
            this.new_lastName = null;
            this.new_email = null;
            this.new_password = null;
            this.new_phone = null;
          },
          error => {
              console.error('error:',error);
               if(error.error.errors?.email){
                this.showError('Error', error.error.errors.email[0]);
              } else if(error.error.message){
                this.showError('Error', error.error.message)
              } else {
              this.showError('Error', error.statusText);
              }
              this.loaderUpdater.emit(false);
          }
        );
      }  else{
        this.showError('Error', 'Please add email or phone');
      }
    }
  }

  addApplication() {
    this.closeAllOther();
    this.newApplicationBroker = true;
    this.appDetailShow = true;
    this.sendApplication(0)
  }

  addApplicationApplicant(){
    this.closeAllOther();
    this.appDetailShow = true;
    this.seeApplications = true;
    this.sendApplication(0);
  }

  addApplicant(){
    this.closeAllOther();
    this.newApplicantForm = true;
  }

  backToApplicantsApplicationsFromNewApplication(){
    this.closeAllOther();
    this.getApplicantsApplications(this.selectedApplicant.id);
    this.showApplicantsApplications = true;
  }

  backTo1FromNewApplicant() {
    console.log('this ran');
    this.closeAllOther();
    this.showApplicants = true;
  }

  backToMainFrom1(){
    if (this.appDetailShow){
      this.appDetailShow = false;
    } else {
      this.closeAllOther();
      this.mainOptions = true;
      setTimeout(() => {
        this.bottomBtnsPosition();
      }, 100);
    }
  }

  backTo1FromApplicant(){
    this.closeAllOther();
    this.showApplicants = true;
    this.selectedApplicant = null;
    this.applicantEvent.emit(this.selectedApplicant);
  }

  backToApplicantsFromNewApplicant(){
    this.closeAllOther();
    this.showApplicants = true;
    this.new_name = null;
    this.new_lastName = null;
    this.new_email = null;
    this.new_password = null;
    this.new_phone = null;
  }

  backToPerferences(){
    if(this.setNewPassword){
      this.closeAllOther();
      this.showProfile = true;
      this.setNewPassword = false;
      this.updatePassword = {
        newPassword: '',
        newPasswordConfirm: '',
      } 
      if(this.showProfile){
        setTimeout(() => {
          this.userUpdateComponent.getUser(this.user);
        }, 100);
      }
    } else{
      this.closeAllOther();
      this.showPerferences = true;
    }
  }

  backToApplicantFromApplicantsApplications(){
    if (this.appDetailShow){
      this.getApplicantsApplications(this.selectedApplicant.id);
      this.appDetailShow = false;
      this.showApplicantsApplications = false;
      this.newApplicationBroker = false;
      this.applicantDetails = true;
    } else {
      this.closeAllOther();
      this.applicantDetails = true;
    }
  }

  closeAllOther(){
    this.mainOptions = false;
    this.showApplicantsApplications = false;
    this.applicantDetails = false;
    this.showApplicants = false;
    this.showPerferences = false;
    this.showProfile = false;
    this.showIdentityProof = false;
    this.showSubscriptions = false;
    this.showBilling = false;
    this.newApplicantForm = false;
    this.showApplicants = false;
    this.newApplication = false;
    this.seeApplications = false;
    this.newApplicationBroker = false;
    this.editApplicantInfo = false
    this.businessInfoSection = false;
  }

  openEditApplications() {
    this.checkLoggedIn();
    this.http.get<any>(environment.apiBaseUrl+'application-list').subscribe(
      response => {
        console.log(response);
        // this.applications = response;
        this.mainOptions = false;
        this.seeApplications = true;
        this.applicationSorting(response);
      },
      error => {
        console.error('Login error:', error);
        this.showError('Error', error.statusText);
      }
    );
  }

  applicationSorting(data: any){
    this.applications= data.sort((a, b) => {
      const dateA = new Date(a[this.selectedOptionApplications]).getTime();
      const dateB = new Date(b[this.selectedOptionApplications]).getTime();
      if (this.selectedOptionApplications === 'application_name') {
        const nameA = a.application_name || '';
        const nameB = b.application_name || '';
        return nameA.localeCompare(nameB);
      } else if(this.selectedOptionApplications === 'created_at'){
        return dateA - dateB;
      } else if(this.selectedOptionApplications === 'updated_at') {
        return dateB - dateA;
      }
    });
  }
  
  onOptionChangeApplications(option: string): void {
    this.selectedOptionApplications = option;
    this.applicationSorting(this.applications);
  }

  showPerferencesFunction(){
    this.showPerferences = true;
    this.mainOptions = false;
  }

  showProfileFunction(){
    this.showProfile = true;
    this.showPerferences = false;
    if(this.showProfile){
      setTimeout(() => {
        this.userUpdateComponent.getUser(this.user);
      }, 100);
    }
  }

  showProfileFunctionApplicant(){
    this.mainOptions = false
    this.showProfileApplicant = true;
    if(this.showProfileApplicant){
      setTimeout(() => {
        this.userUpdateComponent.getUser(this.user);
      }, 100);
    }
  }

  backToPerferencesApplicant(){
    if (this.appDetailShow){
      this.openEditApplications();
      this.appDetailShow = false;
    } else {
      if(this.setNewPassword){
        this.showProfileApplicant = true;
        this.setNewPassword = false;
        this.updatePassword = {
          newPassword: '',
          newPasswordConfirm: '',
        } 
        if(this.showProfileApplicant){
          setTimeout(() => {
            this.userUpdateComponent.getUser(this.user);
          }, 100);
        }
      } else{
        this.mainOptions = true;
        this.seeApplications = false;
        this.showProfileApplicant = false;
        setTimeout(() => {
          this.bottomBtnsPosition();
        }, 100);
      }
    }
  }

  showIdentityProofFunction(){
    this.showIdentityProof = true;
    this.showPerferences = false;
    if(this.showIdentityProof){
      this.checkLoggedIn();
      setTimeout(() => {
        this.embeddedCodeComponent.getEmbeddedCode();
        this.embeddedCodeComponent.getUserEmbeddedSources(this.user.sources);
      }, 100);
    }
  }
  
  showBusinessInfoSection(){
    this.closeAllOther();
    this.businessInfoSection = true;
    if(this.businessInfoSection){
      setTimeout(() => {
        this.businessInformationComponent.getBusinessInfomation(this.user);
      }, 100);
    }
  }
  
  showSubscriptionsFunction(){
    this.showSubscriptions = true;
    this.showPerferences = false;
  }
  
  showBillingFunction(){
    this.http.get<any>(environment.apiBaseUrl+'stripe/portal-url').subscribe(
      response => {
        this.showBilling = true;
        this.showPerferences = false;
        this.portalUrl = response;
        console.log(this.portalUrl);
      },
      error => {
        console.error('Login error:', error);
        this.showError('Error', error.statusText);
      }
    );
    
  }

  openPortal(){
    console.log('this update is active '+ this.portalUrl);
    window.location.href = this.portalUrl;
  }

  showNewsModal(value: string) {
    this.kingstateNewsEvent.emit(value);
  }

  setPaymentMode(){
    this.paymentMode = true;
  }

  copyToClipboardUrl() {
    this.clipboard.copy(this.broker_hash_url);
    this.tooltipText = 'Copied!';

    setTimeout(() => {
      this.tooltipText = 'Copy to clipboard';
    }, 1500);
  }

  toggleSidebar(): boolean {
    const sidebar = document.querySelector('.menu-sidebar');
    const menuIcon = document.getElementById("menuIcon");
    const menuMobile = document.getElementById("mobile-menu");
    
    if (this.mobileScreen){
      if (sidebar.classList.contains('small-sc')) {
        sidebar.classList.remove('small-sc');
        sidebar.classList.add('menu-show');
        menuMobile.classList.remove('mobile-menu-active');
        menuIcon.innerHTML = `<i class="fa fa-solid fa-times"></i>`;
      } else {
        sidebar.classList.add('small-sc');
        sidebar.classList.remove('menu-show');
        menuMobile.classList.add('mobile-menu-active');
        menuIcon.innerHTML = `<i class="fa fa-solid fa-bars"></i>`;
      }
    
      this.sidebarService.toggle(true, 'menu-sidebar');
      this.layoutService.changeLayoutSize();
    
      return false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.mobileScreen = true;
    }else if(this.screenWidth > 768 && this.screenWidth < 1201) {
      this.mobileScreen = false;
      this.tabScreen = true;
    } else{
      this.mobileScreen = false;
      this.tabScreen = false;
    }
  }

  bottomBtnsPosition() {
    const button = document.getElementById('leftBarBottomBtn')
    const viewportHeight = window.innerHeight;
    const windowHeight = window.outerHeight;
    const screenWidth = window.innerWidth;
    
    if(this.mobileScreen && button){
      if (windowHeight > viewportHeight) {
          // The URL bar is visible; adjust the button's position
          const bottomBtns = windowHeight - viewportHeight
          if(bottomBtns >= 150 && bottomBtns >= 130){
            const setBottom = bottomBtns / 1.8
            button.style.bottom = setBottom + 'px';
          } else if(bottomBtns >= 250){
            button.style.bottom = '8%';
          } else {
            const setBottom = bottomBtns / 1.4
            button.style.bottom = setBottom + 'px';
          }
      } else {
          button.style.bottom = '8%';
      }
    } else {
      if(button){
        if(viewportHeight >= 830){
          button.style.bottom = '8%';
          if(screenWidth >= 2120){
            button.style.left = '-14px';
          }
        } else {
          button.style.position = 'relative';
          button.style.left = '-15px';
          button.style.bottom = '0';
        }
      }
    }
  }

  @HostListener('window:load', ['$event'])
  onLoad(event: Event) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.mobileScreen = true;
      setTimeout(() => {
        this.bottomBtnsPosition();
      }, 200);
    } else{
      this.mobileScreen = false;
      setTimeout(() => {
        this.bottomBtnsPosition();
      }, 200);
    }
  }

  // checkBrokerAccountStatus(){
  //   if(accountStatus === "request"){
  //     this.brokerAccountRequest = true;
  //   } else if (accountStatus === "pending"){
  //     this.brokerAccountPending = true;
  //   } else {
  //     this.brokerAccountReject = true;
  //   }
  //  }

  toggleMediaModal(){
    this.bbModalOpen.emit();
  }

  allowDecimal(event: KeyboardEvent) {
    const currentValue = (<HTMLInputElement>event.target).value;
    if (event.key === '.') {
      if (currentValue.includes('.')) {
        event.preventDefault();
      }
    }

    if (!(event.key >= '0' && event.key <= '9') && event.key !== 'Backspace' && event.key !== '.') {
      event.preventDefault();
    }
  }

  triggerEmail() {
    this.http.post<any>(environment.apiBaseUrl+'email-hash-url/'+this.selectedApplication, { email: this.selectedApplicant.email, id: this.selectedApplicant.id }).subscribe(
      response => {
        this.showSuccess('Email Sent','');
      },
      error => {
        this.showError('Error', 'Something went wrong');
      }
    );
  }

  unformattedPhoneNumber(phoneNumber: string) {
    let unformatPhoneNumber = phoneNumber.replace(/-/g, '');
    if(unformatPhoneNumber.length < 10){
      this.showError('Error', 'Incorrect Number');
    } else {
      this.triggerSms(unformatPhoneNumber)
    }
  }

  goToApplication(){
    let closed = false;
    this.setGoToApplication.emit(closed);
  }

  brokerProfile(user: any) {
    if(user.broker !== null && user.broker){
      if(this.user.broker.business_information !== null){
        if(this.user.broker.business_information.logo !== null){
          const brokerProfileUrl = this.user.broker.business_information.logo;
          this.getBrokerProfile = brokerProfileUrl;
          this.checkBrokerProfile = true;
        }
      } else if(this.user.broker.profile_picture !== null){
        const brokerProfileUrl = this.user.broker.profile_picture;
        this.getBrokerProfile = brokerProfileUrl;
        this.checkBrokerProfile = true;
      }
    } else {
      this.checkBrokerProfile = false;
      this.getBrokerProfile = 'assets/images/kingstate_logo.png';
    }
  }

  onInputChange() {
    this.userUpdateChecker = {
      name: this.selectedApplicant.name, 
      last_name: this.selectedApplicant.last_name, 
      email: this.selectedApplicant.email, 
      phone: this.selectedApplicant.phone.replace(/-/g, '')
    }
    this.isUpdateButtonEnabled = Object.keys(this.applicantData).some(key => this.applicantData[key] !== this.userUpdateChecker[key]);
  }

  getCsvModel(value: string, status: string){
    this.csvModalValue.emit({ arg1: value, arg2: status });
  }

  generateCsvFile(value: string){
    if(this.user && this.user.role.name === 'broker'){
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'text/csv'
      });
      if(value === "applicants"){
        this.getApplicantId = this.user.id;
      } else {
        this.getApplicantId = this.selectedApplicant.id
      }
      if(this.getApplicantId !== 0){
        this.http.post(environment.apiBaseUrl+'user/applications/csv', { applicant_id: this.getApplicantId}, { headers, responseType: 'blob' }).subscribe(
          response => {
            const blob = new Blob([response], { type: 'text/csv' });
            saveAs(blob, 'applications.csv');
            this.showSuccess('CSV Generated', '');
          },
          error => {
            this.showError('Error', error.error.message);
          }
        );
      }
    }
  }

  sendApplicationToBoss(app_id: number){
    if(this.user && this.user.role.name === 'broker'){
      this.http.get<any>(environment.apiBaseUrl+'boss/application/'+app_id).subscribe(
        response => {
          this.showSuccess('Application send to Mortgage Boss', '');
        },
        error => {
          this.showError('Error', error.error.message);
        }
      );
    }
  }
  
  sendApplicationToFinmo(app_id: number){
    if(this.user && this.user.role.name === 'broker'){
      this.http.get<any>(environment.apiBaseUrl+'finmo/application/'+app_id).subscribe(
        response => {
          this.showSuccess('Application send to Finmo', '');
        },
        error => {
          console.error('Login error:', error);
          this.showError('Error', error.error.message);
        }
      );
    }
  }

}
